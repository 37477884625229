<template>
  <router-link :to="{ path }" class="custom-btn">
    <bootstrap-icon icon="pencil" size="1x" />
  </router-link>
</template>

<script>
export default {
  name: "EditBtn",
  props: {
    path: { type: String, default: "" },
  },
};
</script>
