<template>
  <div class="table-actions">
    <div
      v-if="actions.includes('perPage')"
      class="table-actions__perPage d-flex align-items-center me-auto"
    >
      <label class="me-2">{{ $t("messages.show") }}</label>
      <v-select
        :clearable="false"
        taggable
        v-model="paginationOption"
        :options="paginationOptions"
        @option:selected="perPageChange"
      />
    </div>
    <div v-if="actions.includes('search')" class="table-actions__search">
      <search @inputChange="searchInput" />
    </div>
    <div v-if="actions.includes('addBtn')" class="table-actions__addBtn">
      <b-button
        :to="addBtnLink"
        class="ms-4 d-flex align-items-center"
        variant="primary"
        @click="addBtnClick"
      >
        <img class="me-2" src="@/assets/images/icons/plus.svg" alt="plus" />
        {{ addBtnText }}
      </b-button>
    </div>
    <div v-if="actions.includes('addBtnEmit')" class="table-actions__addBtn">
      <b-button
        class="ms-4 d-flex align-items-center"
        variant="primary"
        @click="$emit('addBtnClicked')"
      >
        <img class="me-2" src="@/assets/images/icons/plus.svg" alt="plus" />
        {{ addBtnText }}
      </b-button>
    </div>
  </div>
</template>

<script>
import Search from "@/components/Inputs/Search.vue";
import vSelect from "vue-select";

export default {
  components: {
    Search,
    vSelect,
  },
  props: {
    actions: {
      type: Array,
      default() {
        return [];
      },
    },
    addBtnText: { type: String, default: "" },
    addBtnLink: { type: String, default: "" },
    defaultPerPage: { type: Number, default: 15 },
  },
  data() {
    return {
      paginationOption: this.defaultPerPage,
      paginationOptions: [15, 25, 50],
    };
  },
  methods: {
    searchInput(data) {
      this.$emit("onSearchInput", data);
    },
    perPageChange() {
      this.$emit("perPageChange", this.paginationOption);
    }
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>